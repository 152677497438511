/** @jsx jsx */
import * as Yup from "yup"
import { Formik, Field, Form } from "formik"
import { PageProps } from "gatsby"
import { useState } from "react"
import {
  Box,
  Container,
  Heading,
  Label,
  Paragraph,
  Text,
  ThemeUIStyleObject,
  jsx,
} from "theme-ui"

import theme from "gatsby-plugin-theme-ui/index"
import BackgroundSVG from "images/icons/background-light.svg"

import AguilaButton from "components/AguilaButton"
import BannerHero from "components/BannerHero"
import Layout from "components/Layout"

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/

const validationSchema = Yup.object().shape({
  full_name: Yup.string().min(2, "Minimum 2 characters").required("Required"),
  email: Yup.string().email("Invalid email format").required("Required"),
  phone: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
})

interface MyFormValues {
  full_name: string
  email: string
  phone?: string
  contact_dept: string
  message?: string
}
interface ICustomFormValues {
  heading: string
  subHeading: string
  introParagraph: string
  contactDetails: string
}

interface IFormVariations {
  gov_law: ICustomFormValues
  sponsor_partner: ICustomFormValues
  gen_inquiries: ICustomFormValues
  careers: ICustomFormValues
}

const ContactPage = ({ location }: PageProps) => {
  const params = new URLSearchParams(location.search)
  let contactDept = params.get("dept")
  const shouldShowSelect = contactDept === null ? true : false
  contactDept = contactDept ? contactDept : "gen_inquiries"

  const initialValues: MyFormValues = {
    full_name: "",
    email: "",
    phone: "",
    contact_dept: contactDept,
    message: "",
  }

  const formVariations: IFormVariations = {
    gov_law: {
      heading: "contact us",
      subHeading: "Government & Law Enforcement Sales",
      introParagraph:
        "Aguila Ammunition is proud to provide law enforcement and other government institutions with the finest in ammunition, defense products, and security services.",
      contactDetails:
        "<p>Please fill out the form below or contact our Government & LE representative directly at <a href='mailto=LE@aguilaammo.com'>LE@aguilaammo.com</a> or <a href='tel:+1888-452-4019'>888-452-4019.</a> We look forward to hearing from you!</p>",
    },
    sponsor_partner: {
      heading: "contact us",
      subHeading: "Sponsorships & Partnerships",
      introParagraph:
        "Reach out to our team to discuss partnerships, request sponsorship information, or make a donation. We look forward to hearing from you!",
      contactDetails:
        "<p>Please submit the form below or contact us directly at <a href='mailto:marketing@tx-at.com'>marketing@tx-at.com</a></p>",
    },
    gen_inquiries: {
      heading: "contact us",
      subHeading: "General Inquiries & Product Support",
      introParagraph:
        "Have questions or need more information regarding our products? Reach out to our support team!",
      contactDetails:
        "<p>Fill out the short form below or contact us directly at <a href='mailto:info@aguilaammo.com'>info@aguilaammo.com</a>.</p>",
    },
    careers: {
      heading: "contact us",
      subHeading: "Careers",
      introParagraph: "Currently we do not have any openings.",
      contactDetails:
        "<p>To stay updated on content and job openings, check out and follow TXAT / Aguila Ammunition USA here:</p><p><a href='https://www.linkedin.com/company/aguilaammo'>https://www.linkedin.com/company/aguilaammo</a>.</p>",
    },
  }

  function formv3(values: any) {
    // Create the new request
    var xhr = new XMLHttpRequest()
    var url =
      "https://api.hsforms.com/submissions/v3/integration/submit/8105048/10ca0fc0-e130-4553-9257-bc22d905cc7c"

    // Example request JSON:
    var data = {
      // submittedAt: Date().toLocaleString(),
      fields: [
        {
          name: "email",
          value: values.email,
        },
        {
          name: "firstname",
          value: values.full_name.split(" ")[0],
        },
        {
          name: "lastname",
          value: values.full_name.split(" ")[1],
        },
        {
          name: "phone",
          value: values.phone,
        },
        {
          name: "message",
          value: values.message,
        },
      ],
      context: {
        // hutk: ":hutk", // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
        pageUri: location.href,
        pageName: `Contact ${values.contact_dept}`,
      },
    }

    var final_data = JSON.stringify(data)

    xhr.open("POST", url)
    // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
    xhr.setRequestHeader("Content-Type", "application/json")

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4 && xhr.status == 200) {
        setFormSuccess(true) // alert(xhr.responseText) // Returns a 200 response if the submission is successful.
      } else if (xhr.readyState == 4 && xhr.status == 400) {
        const hubspotResponse = JSON.parse(xhr.responseText)
        if (hubspotResponse.errors[0].errorType === "INVALID_EMAIL") {
          setFormSuccess(true)
        } else {
          alert(xhr.responseText) // Returns a 400 error the submission is rejected.
        }
      } else if (xhr.readyState == 4 && xhr.status == 403) {
        console.log(403)
        alert(xhr.responseText) // Returns a 403 error if the portal isn't allowed to post submissions.
      } else if (xhr.readyState == 4 && xhr.status == 404) {
        console.log(404)
        alert(xhr.responseText) //Returns a 404 error if the formGuid isn't found
      }
    }

    // Sends the request

    xhr.send(final_data)
  }

  const formContent = formVariations[contactDept as keyof IFormVariations]
  const [formSuccess, setFormSuccess] = useState(false)

  return (
    <Layout
      seo={{ metaTitle: "Contact Aguila Ammunition" }}
      location={location}
    >
      <Box sx={{ position: "relative" }}>
        <BackgroundSVG
          sx={{
            position: "absolute",
            zIndex: -1,
            transformOrigin: "center",
            top: 0,
            left: 0,
            width: "auto",
            minHeight: "500px",
            height: "100%",
            transform: "translate(-40%, 0)",
            "& > g": {
              opacity: 0.2,
            },
            "& *": {
              fill: "#d6d1c4cf",
            },
          }}
        />
        <BannerHero
          heading={formContent.heading}
          subHeading={formContent.subHeading}
          content={formContent.introParagraph}
        />
        <Box
          as="section"
          sx={{
            position: "relative",
            mb: [16, null, null, 36],
            mt: ["-140px", null, null, "-240px"],
          }}
        >
          <Container
            variant="layout.narrow"
            sx={{ backgroundColor: "lightTan", boxShadow: 0 }}
          >
            {!formSuccess && (
              <Box
                sx={{
                  mx: "auto",
                  py: 18,
                  maxWidth: "520px",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    mb: [10, null, null, 18],
                    ...(theme.styles as ThemeUIStyleObject),
                  }}
                  dangerouslySetInnerHTML={{
                    __html: formContent.contactDetails,
                  }}
                />
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  enableReinitialize
                  onSubmit={(values, actions) => {
                    // alert(JSON.stringify(values, null, 2))
                    formv3(values)
                    // setFormSuccess(true)
                    actions.setSubmitting(false)
                  }}
                >
                  {({ errors, touched }) => (
                    <Form>
                      <Box sx={{ position: "relative", width: "100%", mb: 10 }}>
                        <Field
                          sx={{ variant: "forms.input", width: "100%" }}
                          id="full_name"
                          name="full_name"
                          placeholder="First and Last Name*"
                        />
                        <Label htmlFor="full_name" sx={{ pl: 4 }}>
                          First and Last Name*
                        </Label>
                        <Text
                          sx={{
                            color: "secondary",
                            display: "block",
                            textAlign: "right",
                            pt: 1.5,
                          }}
                        >
                          {errors.full_name ? errors.full_name : null}
                        </Text>
                      </Box>
                      <Box sx={{ position: "relative", width: "100%", mb: 10 }}>
                        <Field
                          sx={{ variant: "forms.input", width: "100%" }}
                          id="email"
                          name="email"
                          placeholder="Email address*"
                        />
                        <Label htmlFor="email" sx={{ pl: 4 }}>
                          Email address*
                        </Label>
                        <Text
                          sx={{
                            color: "secondary",
                            display: "block",
                            textAlign: "right",
                            pt: 1.5,
                          }}
                        >
                          {errors.email ? errors.email : null}
                        </Text>
                      </Box>
                      <Box sx={{ position: "relative", width: "100%", mb: 10 }}>
                        <Field
                          sx={{
                            variant: "forms.input",
                            width: "100%",
                            maxWidth: "350px",
                          }}
                          id="phone"
                          name="phone"
                          placeholder="Phone Number"
                        />
                        <Label htmlFor="phone" sx={{ pl: 4 }}>
                          Phone Number
                        </Label>
                        <Text
                          sx={{
                            color: "secondary",
                            display: "block",
                            textAlign: "right",
                            pt: 1.5,
                          }}
                        >
                          {errors.phone ? errors.phone : null}
                        </Text>
                      </Box>
                      {/* {shouldShowSelect && ( */}
                      <Box sx={{ mb: 10 }}>
                        <Field
                          as="select"
                          id="contact_dept"
                          name="contact_dept"
                          sx={{
                            variant: "forms.select",
                            width: "100%",
                            maxWidth: "350px",
                            mb: 2,
                          }}
                        >
                          <option value="gen_inquiries">
                            General Inquires and Product Support
                          </option>
                          <option value="sponsor_partner">
                            Sponsorships and Partnerships
                          </option>
                          <option value="gov_law">
                            Goverment & Law Enforcement Sales
                          </option>
                          <option value="careers">Careers</option>
                        </Field>
                        <Label htmlFor="contact_dept" sx={{ pl: 4 }}>
                          Who are you contacting?*
                        </Label>
                      </Box>
                      {/* )} */}
                      <Field
                        as="textarea"
                        id="message"
                        name="message"
                        placeholder="Message"
                        rows="8"
                        sx={{
                          variant: "forms.textarea",
                          width: "100%",
                          resize: "vertical",
                          mb: 10,
                          "&::placeholder": {
                            fontFamily: "body",
                          },
                        }}
                      />
                      <AguilaButton
                        type="submit"
                        text="Submit"
                        variant="textOnly"
                        sx={{ backgroundColor: "transparent" }}
                      />
                    </Form>
                  )}
                </Formik>
              </Box>
            )}
            {formSuccess && (
              <Box
                sx={{
                  mx: "auto",
                  py: 18,
                  maxWidth: "520px",
                  width: "100%",
                  minHeight: "350px",
                }}
              >
                <Heading as="h2" variant="title">
                  Thanks for contacting us
                </Heading>
                <Paragraph sx={{ mb: 8 }}>
                  We’ll be in touch soon. In the meantime, check out some of our
                  ammo!
                </Paragraph>
                <AguilaButton url="/ammunition/all" text="View products" />
              </Box>
            )}
          </Container>
        </Box>
      </Box>
    </Layout>
  )
}

export default ContactPage
